import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalHapus: false,
    rwposjab: {
      id: '',
      unor: {
          id: '',
          nama: '',
          atasan: {
              id: '',
              nama: ''
          },
          induk: {
              id: '',
              id_simpeg: '',
              nama: ''
          }
      },
      jenis_jabatan: {
          id: '',
          nama: '',
      },
      jabatan_status: {
          id: '',
          nama: '',
      },
      eselon: {
          id: '',
          nama: ''
      },
      golongan_pns: {
          id: '',
          nama: '',
      },
      golongan_pppk: {
          id: '',
          nama: '',
      },
      tmt_jabatan: '',
      tunjangan: 0,
      pejabat_sk: '',
      nomor_sk: '',
      tgl_sk: '',
      doc: '',
      userId: '',
      NCSISTIME: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalHapus = value
    },
    hapusModal(state, value) {
      state.rwposjab = value
    },
  }
})
