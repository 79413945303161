<template>
  <div v-if="session">
    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div v-if="ldata">
      <v-progress-linear
        color="primary accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </div>

    <div v-else>
      <div v-if="cek == 0">
        <v-alert prominent type="error">
          <v-row align="center">
            <v-col class="grow">
              Data <strong>Posisi dan Jabatan</strong> Anda Tidak Ditemukan.
            </v-col>
            <v-col
              class="shrink"
              v-if="session.authorities.some((item) => item === 'CRUD_POSJAB')"
            >
              <ModalAdd />
            </v-col>
          </v-row>
        </v-alert>
      </div>

      <div v-else>
        <v-card>
          <v-app-bar color="primary" short dense dark flat>
            <v-toolbar-title>Posisi Dan Jabatan</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="editItem(data)" v-if="session.authorities.some((item) => item === 'CRUD_POSJAB')">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </v-app-bar>

          <div class="pa-5">
            <v-row dense class="mb-n5">
              <v-col cols="12" md="6">
                <span class="subtitle-2">Unit Organisasi</span>
                <v-text-field
                  v-model="data.unor.nama"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <span class="subtitle-2">Unit Organisasi Induk</span>
                <v-text-field
                  v-model="data.unor.induk.nama"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="mb-n5">
              <v-col cols="12" md="6">
                <span class="subtitle-2">Jenis Jabatan</span>
                <v-text-field
                  v-model="data.jenis_jabatan.nama"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <span class="subtitle-2">Jenjang Jabatan</span>
                <v-text-field
                  v-model="data.jabatan_status.nama"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="mb-n5">
              <v-col cols="12" md="12">
                <span class="subtitle-2">Nama Jabatan</span>
                <v-text-field
                  v-model="data.nama_jabatan"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="mb-n5">
              <v-col cols="12" md="6">
                <span class="subtitle-2">Eselonisasi</span>
                <v-text-field
                  v-model="data.eselon.nama"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="data.jenis_asn == 'PNS'">
                <span class="subtitle-2">Golongan Ruang</span>
                <v-text-field
                  v-model="data.golongan_pns.nama"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="data.jenis_asn == 'PPPK'">
                <span class="subtitle-2">Golongan Ruang</span>
                <v-text-field
                  v-model="data.golongan_pppk.nama"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="mb-n5">
              <v-col cols="12" md="12">
                <span class="subtitle-2">TMT Jabatan</span>
                <v-text-field
                  v-model="data.tmt_jabatan"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="mb-n5">
              <v-col cols="12" md="12">
                <span class="subtitle-2">Tunjangan Jabatan</span>
                <v-text-field
                  v-model="data.tunjangan"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="mb-n5">
              <v-col cols="12" md="4">
                <span class="subtitle-2">Pejabat Yang Menandatangani SK</span>
                <v-text-field
                  v-model="data.pejabat_sk"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <span class="subtitle-2">Nomor SK</span>
                <v-text-field
                  v-model="data.nomor_sk"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <span class="subtitle-2">Tanggal SK</span>
                <v-text-field
                  v-model="data.tgl_sk"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                  class="mt-2"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="mt-3" >
              <v-col cols="12" md="2">
                <div v-if="data.doc != null">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red"
                        block
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="download(data.id)"
                      >
                        <v-icon class="mr-2"> mdi-file-pdf-box </v-icon>
                        Dokumen
                      </v-btn>
                    </template>
                    <span> Dokumen </span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <v-dialog v-model="modalPDF" :max-width="dekstop">
          <v-card height="90vh" style="overflow: hidden">
            <v-toolbar dark color="primary" dense flat>
              <v-toolbar-title>{{ namaPDF }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closePDF()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card flat class="pa-2">
              <v-progress-linear
                indeterminate
                color="primary"
                v-if="lbtn"
              ></v-progress-linear>

              <div v-else>
                <embed
                  :src="defaultPDF"
                  type="application/pdf"
                  style="height: 80vh; width: 100%"
                />
              </div>
            </v-card>
          </v-card>
        </v-dialog>

        <ModalEdit />
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import ModalAdd from "@/components/asn/profil/rwposjab/ModalAdd";
import ModalEdit from "@/components/asn/profil/rwposjab/ModalEdit";
// import rwposjabModalHapus from "@/components/asn/profil/rwposjab/ModalHapus";

import modal_editState from "@/store/asn/rwposjab/modal_edit";
import modal_hapusState from "@/store/asn/rwposjab/modal_hapus";
import refreshView from "@/store/asn/rwposjab/view";

export default {
  name: "rwposjab",

  components: {
    ModalAdd,
    ModalEdit,
    //   rwposjabModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),

    dekstop: "75%",
    session: "",
    get_nip: "",
    cek: 0,

    modalPDF: false,
    defaultPDF: "",
    namaPDF: "",
    loading: false,
    lbtn: false,
    ldata: true,

    data: {},
  }),

  async mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_ASN + "posjab/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.ldata = false;
          refreshView.commit("refreshData", false);
          this.cek = res.data.mapData.data.length;
          this.data = res.data.mapData.data[0];
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      modal_editState.commit("toggleModal", true);
      modal_editState.commit("editModal", item);
    },

    deleteItem(item) {
      this.hapusIndex = this.rwposjabs.indexOf(item);
      this.rwposjab = Object.assign({}, item);
      modal_hapusState.commit("toggleModal", true);
      modal_hapusState.commit("hapusModal", Object.assign({}, item));
    },

    download(value) {
      this.lbtn = true;
      this.modalPDF = true;
      this.namaPDF = "Dokumen";

      const url =
        process.env.VUE_APP_API +
        "/idasn/posjab/doc/" +
        this.get_nip +
        "/" +
        value +
        "/doc";

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.defaultPDF = URL.createObjectURL(res.data);
          this.lbtn = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    closePDF() {
      this.modalPDF = false;
    },
  },
};
</script>
