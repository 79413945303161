import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    editedIndex: -1,

    dataUtama: {
      id: '',
      SAPK: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },

    rwposjab: {
      id: '',
      unor: {
          id: '',
          nama: '',
          atasan: {
              id: '',
              nama: ''
          },
          induk: {
              id: '',
              id_simpeg: '',
              nama: ''
          }
      },
      jenis_jabatan: {
          id: '',
          nama: '',
      },
      jabatan_status: {
          id: '',
          nama: '',
      },
      eselon: {
          id: '',
          nama: ''
      },
      golongan_pns: {
          id: '',
          nama: '',
      },
      golongan_pppk: {
          id: '',
          nama: '',
      },
      jenis_asn: '',
      tmt_jabatan: '',
      tunjangan: 0,
      pejabat_sk: '',
      nomor_sk: '',
      tgl_sk: '',
      doc: '',
      userId: '',
      NCSISTIME: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },
    
    editModal(state, value) {
      state.rwposjab = value
    },

    editDatautama(state, value) {
      state.dataUtama = value
    },

    indexItem(state, value) {
      state.editedIndex = value
    }
  }
})
