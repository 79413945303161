<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small fab text v-bind="attrs" v-on="{ ...tooltip, ...modal }">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Data</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="subtitle-1">
          Tambah Riwayat Posisi Dan Jabatan
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-6">
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Unit Organisasi</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-dialog v-model="menuUnor" max-width="70%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      flat
                      outlined
                      v-model="selected.name"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hint="Unit Organisasi"
                      persistent-hint
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-form ref="pilihUnor">
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Pilih Unit Organisasi
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider class="pa-n5"></v-divider>
                      <v-list style="max-height: 300px" class="overflow-y-auto">
                        <div class="pl-5 pr-5 pt-3">
                          <div>
                            <v-autocomplete
                              :items="refUnor"
                              item-value="id"
                              item-text="nama"
                              outlined
                              dense
                              @change="unorChange"
                              return-object
                            >
                            </v-autocomplete>
                          </div>
                          <div>
                            <v-treeview
                              activatable
                              :items="refUnorsub"
                              item-text="namaUnor"
                              item-children="bawahan"
                              return-object
                              :active.sync="selection"
                              selection-type="independent"
                            ></v-treeview>
                          </div>
                        </div>
                      </v-list>
                      <div class="text-right pb-3">
                        <v-btn text @click="clearUnor()"> Clear </v-btn>
                        <v-btn color="error" text @click="menuUnor = false">
                          Batal
                        </v-btn>
                        <v-btn color="primary" text @click="menuUnor = false">
                          Pilih
                        </v-btn>
                      </div>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-col>

              <v-col cols="12" md="5">
                <v-text-field
                  v-model="namaunorinduk"
                  hint="Unit Organisasi Induk"
                  persistent-hint
                  dense
                  flat
                  outlined
                  filled
                  disabled
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Jenjang Jabatan</span>
              </v-col>

              <v-col cols="12" md="10">
                <v-dialog v-model="Djj" scrollable max-width="50%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="sjj.nama"
                      dense
                      flat
                      outlined
                      readonly
                      v-on="on"
                      v-bind="attrs"
                    ></v-text-field>
                  </template>

                  <v-form ref="pilihjj">
                    <v-card class="pa-3">
                      <div>
                        <strong> Pilih Jenis Jabatan </strong>
                      </div>

                      <v-divider class="my-3"></v-divider>

                      <div class="mb-n5">
                        <v-select
                          v-model="jenis_asn"
                          outlined
                          dense
                          return-object
                          :items="refjj"
                          @change="cjj"
                          item-key="id"
                          item-text="nama"
                        >
                        </v-select>
                      </div>

                      <v-card-text style="height: 300px">
                        <div>
                          <v-treeview
                            activatable
                            return-object
                            :items="bawahan"
                            :active.sync="seljj"
                            item-key="id"
                            item-text="nama"
                            item-children="bawahan"
                            selection-type="independent"
                          ></v-treeview>
                        </div>
                      </v-card-text>

                      <v-divider class="my-3"></v-divider>

                      <div class="text-right pb-3">
                        <v-btn
                          depressed
                          dark
                          color="grey darken-1"
                          @click="clearjj()"
                        >
                          Clear
                        </v-btn>

                        <v-btn
                          depressed
                          class="mx-2"
                          color="error"
                          @click="Djj = false"
                        >
                          Batal
                        </v-btn>

                        <v-btn depressed color="primary" @click="Djj = false">
                          Pilih
                        </v-btn>
                      </div>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            class="mt-n5"
            v-if="sjj.id_jenis_jabatan == 1 && sjj.id != 9"
          >
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Jabatan</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-text-field v-model="nama_jabatan" outlined dense>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5" v-if="sjj.id_jenis_jabatan == 4">
            <!-- JABATAN FUNGSIONAL -->
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Jabatan</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-autocomplete
                  :items="reffungsional"
                  dense
                  outlined
                  return-object
                  item-value="id"
                  item-text="nama"
                  placeholder="Cari"
                  prepend-inner-icon="mdi-database-search"
                  @change="onFungsional"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            class="mt-n5"
            v-if="sjj.id_jenis_jabatan == 2 || sjj.id == 9"
          >
            <!-- JABATAN PELAKSANA -->
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Jabatan</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-autocomplete
                  :items="refpelaksana"
                  :loading="lautocomplate"
                  :search-input.sync="spelaksana"
                  dense
                  outlined
                  hide-no-data
                  hide-selected
                  return-object
                  item-value="id"
                  item-text="nama"
                  placeholder="Cari"
                  prepend-inner-icon="mdi-database-search"
                  @change="onPelaksana"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Eselonisasi</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-select
                  v-model="eselon_id"
                  :items="refEselon"
                  item-text="nama"
                  item-value="id"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5" v-if="jenis_asn.nama == 'PNS'">
            <!-- GOLONGAN PNS -->
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Golongan</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-autocomplete
                  v-model="pns_golongan_id"
                  :items="ref_pns_gol"
                  item-value="id"
                  outlined
                  dense
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.pangkat }} - {{ data.item.nama }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.pangkat }} - {{ data.item.nama }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5" v-if="jenis_asn.nama == 'PPPK'">
            <!-- GOLONGAN PPPK -->
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Golongan</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-autocomplete
                  v-model="p3k_golongan_id"
                  :items="ref_p3k_gol"
                  item-value="id"
                  outlined
                  dense
                >
                  <template slot="selection" slot-scope="data">
                    Golongan - {{ data.item.nama }}
                  </template>
                  <template slot="item" slot-scope="data">
                    Golongan - {{ data.item.nama }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>TMT Jabatan</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-menu
                  v-model="tmtJabatanPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tmt_jabatan"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tmt_jabatan"
                    @input="tmtJabatanPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tunjangan Jabatan</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-text-field
                  v-model="tunjangan_jabatan"
                  persistent-hint
                  dense
                  flat
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Surat Keputusan (SK)</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  hint="Pejabat"
                  v-model="pejabat_sk"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  hint="Nomor"
                  v-model="nomor_sk"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="skTanggalPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tanggal_sk"
                      hint="Tanggal"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tanggal_sk"
                    @input="skTanggalPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-divider></v-divider>

          <!-- Upload File -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-5">
                <span>Upload</span>
              </v-col>
              <v-col cols="5">
                <v-file-input
                  class="mt-3"
                  outlined
                  dense
                  flat
                  prepend-icon
                  accept=".pdf"
                  label="Dokumen"
                  placeholder="Pilih File"
                  append-icon="mdi-paperclip"
                  @change="onUpload"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-col>

          <hr />

          <div class="mt-5 mr-5 ml-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="add()">
              SIMPAN
            </v-btn>
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import getRef from "@/helper/getReferensi.js";
import refreshView from "@/store/asn/rwposjab/view";

export default {
  computed: {
    selected() {
      if (!this.selection.length) {
        return "kosong";
      } else {
        let dataUnorSelect = {
          id: this.selection[0].id,
          name: this.selection[0].namaUnor,
          jabatan: this.selection[0].namaJabatan,
          induk: {
            id_sapk: this.selection[0].induk.id_sapk,
            id_simpeg: this.selection[0].induk.id_simpeg,
            nama: this.selection[0].induk.nama,
          },
        };
        return dataUnorSelect;
      }
    },

    sjj() {
      if (!this.seljj.length) {
        return "kosong";
      } else {
        let data = {
          id: this.seljj[0].id,
          id_jenis_jabatan: this.seljj[0].id_jenis_jabatan,
          nama: this.seljj[0].nama,
          atasan: this.seljj[0].atasan,
          kelompok: this.seljj[0].kelompok,
        };
        return data;
      }
    },
  },

  watch: {
    async ModalAdd(value) {
      if (value) {
        this.refjj = await getRef.new_jj(this.token);
        this.refUnor = await getRef.Unor(this.token);
        this.refEselon = await getRef.Eselon(this.token);
        this.ref_pns_gol = await getRef.Golongan(this.token);
        this.ref_p3k_gol = await getRef.PPPK_Golongan(this.token);
        this.reffungsional = await getRef.Jabatanfungsional(this.token);
      }
    },

    selected() {
      this.namaunorinduk = this.selected.induk.nama;
      if (this.sjj.id_jenis_jabatan == 1 && this.sjj.id != 9) {
        this.nama_jabatan = this.selected.jabatan.toUpperCase();
      }
    },

    spelaksana(value) {
      let length = value ? value.length : 0;

      if (length > 5) {
        this.lautocomplate = true;
        const url = process.env.VUE_APP_ASN + "ref/pelaksana";

        let data = {
          cari: value,
        };

        this.http
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then(async (res) => {
            this.lautocomplate = false;
            this.refpelaksana =
              res.data.mapData.referensi.jabatanFungsionalUmum;
          })
          .catch((error) => {
            console.log(error.response.status);
            console.log("Error", error.message);
          });
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    get_nip: "",
    spelaksana: "",

    ModalAdd: false,
    Djj: false,

    btnLoading: true,
    lautocomplate: false,

    menuUnor: false,
    selection: [],

    // Referensi
    refjj: [],
    seljj: [],
    bawahan: [],
    refEselon: [],
    refUnor: [],
    refUnorsub: [],
    ref_pns_gol: [],
    ref_p3k_gol: [],
    refpelaksana: [],
    reffungsional: [],

    // Date Picker
    tmtJabatanPicker: false,
    skTanggalPicker: false,

    // v-model
    eselon_id: "",
    pns_golongan_id: "",
    p3k_golongan_id: "",
    tmt_jabatan: "",
    tunjangan_jabatan: "",
    Unor: "",
    pejabat_sk: "",
    nomor_sk: "",
    tanggal_sk: "",
    namaUnor: "",
    nama_jabatan: "",
    namaunorinduk: "",
    jenis_asn: "",
    jabfung: "",
    jabfungum: "",

    // UPLOAD FILE
    doc: "",
  }),

  async mounted() {
    this.token = await Cookie.get("token");
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    cjj(value) {
      this.bawahan = value.bawahan;
    },

    clearjj() {
      this.$refs.pilihjj.reset();
    },

    default() {
      this.eselon_id = "";
      this.pns_golongan_id = "";
      this.p3k_golongan_id = "";
      this.tmt_jabatan = "";
      this.tunjangan_jabatan = "";
      this.Unor = "";
      this.pejabat_sk = "";
      this.nomor_sk = "";
      this.tanggal_sk = "";
      this.namaUnor = "";
      this.nama_jabatan = "";
      this.namaunorinduk = "";
      this.jenis_asn = "";
      this.jabfung = "";
      this.jabfungum = "";
      this.doc = "";
    },

    async add() {
      this.btnLoading = false;

      const data = new FormData();
      data.append("doc", this.doc);
      data.append("jenis_jabatan_id", this.sjj.id_jenis_jabatan);
      data.append("status_jabatan_id", this.sjj.id);
      data.append("instansi_id", "A5EB03E23C74F6A0E040640A040252AD");
      data.append("unor_id", this.selected.id);
      data.append("eselon_id", this.eselon_id);
      data.append("jenis_asn", this.jenis_asn.nama);
      data.append("pns_golongan_id", this.pns_golongan_id);
      data.append("pppk_golongan_id", this.p3k_golongan_id);
      data.append("jabfung", this.jabfung);
      data.append("jabfungum", this.jabfungum);
      data.append("nama_jabatan", this.nama_jabatan);
      data.append("tmt_jabatan", this.tmt_jabatan);
      data.append("tunjangan_jabatan", this.tunjangan_jabatan);
      data.append("pejabat_sk", this.pejabat_sk);
      data.append("nomor_sk", this.nomor_sk);
      data.append("tanggal_sk", this.tanggal_sk);
      data.append("unor_simpeg", this.selected.induk.id_simpeg);

      const url = process.env.VUE_APP_ASN + "posjab/nip/" + this.get_nip;

      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.reset();
          this.ModalAdd = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
            this.reset();
            this.ModalAdd = false;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
            this.reset();
            this.ModalAdd = false;
          }
        });
    },

    closeModal() {
      this.default();
      this.reset();
      this.ModalAdd = false;
    },

    clearUnor() {
      this.$refs.pilihUnor.reset();
    },

    async unorChange(value) {
      const token = await Cookie.get("token");
      const response = await getRef.subUnor(token, value.id);
      this.refUnorsub = response;
    },

    onUpload(value) {
      this.doc = value;
    },

    onFungsional(value) {
      this.jabfung = value.id;
      this.nama_jabatan = value.nama;
    },

    onPelaksana(value) {
      this.jabfungum = value.id;
      this.nama_jabatan = value.nama;
    },
  },
};
</script>
